import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <section className="inner-banner " style={{ marginTop: "105px" }}>
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-4 pb-sm-4">
            <h4 className="inner-text-title font-weight-bold pt-sm-5 pt-4">
              Contact Us
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Contact
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-contact-11 py-5" id="contact">
        <div className="container py-md-5 py-4">
          <h3 className="title-style text-center mb-5">
            <span>Contact </span> Us
          </h3>
          <div className="row text-center mb-5 pb-lg-5 pb-sm-4">
            <div className="col-lg-3 col-sm-6 contact-info">
              <i className="fas fa-map-marked-alt" />
              <h4>Location</h4>
              <p>{companyaddress}</p>
            </div>
            <div className="col-lg-3 col-sm-6 contact-info mt-md-0 mt-4">
              <i className="fas fa-headset" />
              <h4>Phone</h4>
              <p>{companynumber}</p>
            </div>
            <div className="col-lg-3 col-sm-6 contact-info mt-lg-0 mt-4">
              <i className="fas fa-envelope-open-text" />
              <h4>Email</h4>
              <p>{companyemail}</p>
            </div>
            <div className="col-lg-3 col-sm-6 contact-info mt-lg-0 mt-4">
              <i className="fas fa-globe" />
              <h4>Website</h4>
              <p>{companyurl}</p>
            </div>
          </div>
          <div
            className="form-inner-cont mx-auto"
            style={{ maxWidth: "800px" }}
          >
            <form
              id="contactForm"
              action="/php/thankyou-contact.php "
              method="post"
              onSubmit={(event) => handleSubmit(event)}
            >
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="control-group">
                    <input
                      className="form-control border-0 p-4"
                      onChange={(event) => handleFnameChange(event)}
                      type="text"
                      id="fname"
                      name="fname"
                      value={fname}
                      placeholder="Enter your first name"
                      required
                    />
                    <p class="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="control-group">
                    <input
                      className="form-control border-0 p-4"
                      onChange={(event) => handlelnameChange(event)}
                      type="text"
                      id="lname"
                      name="lname"
                      value={lname}
                      placeholder="Enter your last name"
                      required
                    />
                    <p class="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="control-group">
                    <input
                      className="form-control border-0 p-4"
                      onChange={(event) => handleemailChange(event)}
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      placeholder="Enter your Email"
                      required
                    />
                    <p class="help-block text-danger"></p>
                  </div>
                </div>{" "}
                <div className="col-12">
                  <div className="control-group">
                    <input
                      className="form-control border-0 p-4"
                      onChange={(event) => handlesubjectChange(event)}
                      type="text"
                      id="subject"
                      name="subject"
                      value={subject}
                      placeholder="Enter subject"
                      required
                    />
                    <p class="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="control-group">
                    <textarea
                      className="form-control border-0 p-4"
                      id="message"
                      name="message"
                      rows={1}
                      placeholder="Enter Message"
                      defaultValue={""}
                      value={message}
                      onChange={(event) => handlemessageChange(event)}
                    />
                    <p class="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-primary py-3 px-5"
                    type="submit"
                    defaultValue=""
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
            <h5 style={{ color: "red" }}>{result}</h5>
          </div>
        </div>
      </section>
      <div className="map">
       
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.2768225293917!2d73.02876397196978!3d19.007519714764744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3075bf5c591%3A0x63aebebcd2cca5fe!2sPlot%20No%2047%2C%20Belle%20Vista%2C%20Sector%2015%2C%20CBD%20Belapur%2C%20Navi%20Mumbai%2C%20Maharashtra%20400614!5e0!3m2!1sen!2sin!4v1695966612009!5m2!1sen!2sin"
          width="100%"
          height={400}
          frameBorder={0}
          style={{ border: "0px" }}
          allowFullScreen
        />
      </div>
      <Footer />
    </>
  );
}
