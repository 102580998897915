import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner " style={{ marginTop: "105px" }}>
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-4 pb-sm-4">
            <h4 className="inner-text-title font-weight-bold pt-sm-5 pt-4">
            About Us
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                About
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-about py-5" id="about">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 section-width pe-xl-5">
              <h3 className="title-style">
                ABOUT <span>US</span>
              </h3>
              <p className="mt-4">
                At {companyname}, we specialize in providing comprehensive
                Freight Forwarding and business trade solutions that empower
                your business to extend its presence to every desired corner.
                Leveraging our profound understanding of both internal and
                external customer demands, we strategically approach our
                services, delivering an all-encompassing solution that propels
                your brand forward. Our core philosophy revolves around
                achieving operational excellence, offering viable and
                cost-effective technology-driven solutions, even for the most
                intricate logistics and supply chain management challenges. We
                embody this philosophy through our work, fueled by a
                forward-thinking and progressive mindset.
              </p>
            </div>
            <div className="col-lg-6 history-info mt-lg-0 mt-5 pt-md-4 pt-2">
              <div className="position-relative img-border">
                <img
                  src="assets/images/About 1.jpg"
                  className="img-fluid video-popup-image"
                  alt="video-popup"
                />
              </div>
            </div>
            <p className="mt-4">
              Irrespective of your business's size, be it small, medium, or
              large, we address all your technological needs, infusing a
              contemporary flair that ensures the seamless flow of your
              operations. Our dedication to innovation remains steadfast; we
              continuously seek fresh and inventive approaches to tackle the
              challenges our clients face today and foresee those they may
              encounter in the future. Our approach is marked by a readiness to
              explore new perspectives and translate our insights into action,
              guaranteeing that our clients receive cutting-edge, tailor-made
              solutions that precisely match their unique requirements.
            </p>
          </div>
        </div>
      </section>
      <section className="home-services py-5" id="services">
        <div className="container py-lg-5 py-md-4 py-2">
          <h3 className="title-style text-center mb-lg-5 mb-4">
            How <span>it </span>Works
          </h3>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="box-wrap" style={{ height: "280px" }}>
                <div className="icon"></div>
                <h4 className="number">01</h4>
                <h4>
                  <Link>Discover Options</Link>
                </h4>
                <p>
                  Browse through our offerings encompassing air, sea, road
                  transportation, warehouse solutions, and digital logistics.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="box-wrap" style={{ height: "280px" }}>
                <div className="icon"></div>
                <h4 className="number">02</h4>
                <h4>
                  <Link>Request a Quotation</Link>
                </h4>
                <p>
                  Engage with our logistics specialists to find a tailored
                  solution that aligns with your company's needs.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="box-wrap" style={{ height: "280px" }}>
                <div className="icon"></div>
                <h4 className="number">03</h4>
                <h4>
                  <Link>Place Your Order</Link>
                </h4>
                <p>
                  Tailor your order to match your specific supply chain
                  requirements
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="box-wrap" style={{ height: "280px" }}>
                <div className="icon"></div>
                <h4 className="number">04</h4>
                <h4>
                  <Link>Take Delivery of Your Shipment</Link>
                </h4>
                <p>
                  Enhance your upcoming shipment by receiving your goods and
                  reviewing your processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="w3l-servicesblock2 py-5" id="services">
        <div className="container py-lg-5 py-md-4 py-2">
          <h3 className="title-style text-center mb-lg-5 mb-4">
            Services we're <span>offering</span>
          </h3>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Sea" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Sea Freight.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Sea Freight</h4>
                    <p> Our state-of-the-art solutions create a pathway to your
                    sales and procurement markets, ensuring your shipments reach</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Air" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Air Freight.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Air Freight</h4>
                    <p> The partnership between {companyname} and multiple
                    major airlines ensures cost-effective rates, predictable</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Surface" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Surface Transportation.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Surface Transportation</h4>
                    <p>Our land transport services are precisely tailored to meet
                    the specific needs of our clients. These services offer you</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Warehousing" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Warehousing & Distribution.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Warehousing & Distribution</h4>
                    <p>We provide top-tier warehouse services to cater to our
                    clients' needs for secure product storage and transit.</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Multimodal" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Multimodal Transportation.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Multimodal Transportation</h4>
                    <p>When transportation is managed by a single organization,
                    your company can achieve significant time and cost savings.</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Projects" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Projects & Break Bulk.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Projects & Break Bulk</h4>
                    <p> We excel in the management of project cargo, demanding a
                    high degree of skill, care, and precision. Our expertise</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
