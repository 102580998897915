import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <footer className="w3l-footer-16 py-5">
        <div className="container pt-md-5 pt-sm-4 pb-sm-2">
          <div className="row">
            <div className="col-lg-4">
              <Link className="footer-logo d-flex align-items-center" to="/">
                <img
                  src="assets/images/logo.png"
                  alt="logo"
                  style={{ height: "90px" }}
                ></img>
              </Link>
              <p className="mt-3">
                At Pack & Send Enterprises, we specialize in providing
                comprehensive Freight Forwarding and business trade solutions
                that empower your business to extend its presence to every
                desired corner. Leveraging our profound understanding of both
                internal and external customer demands, we strategically
                approach our services, delivering an all-encompassing solution
                that propels your brand forward.
              </p>
              <div className="columns-2 mt-4"></div>
            </div>

            <div className="col-lg-2">
              <h3 className>Quick Links</h3>
              <ul className="footer-gd-16">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/About">About Us</Link>
                </li>
                <li>
                  <Link to="/Services">Services</Link>
                </li>
                <li>
                  <Link to="/Trading">Trading Services</Link>
                </li>
                <li>
                  <Link to="/Contact">Contact Us</Link>
                </li>{" "}
                <li>
                  <Link to="/Getquote">Request A Quote</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <h3 className>Services Links</h3>
              <ul className="footer-gd-16">
                <li>
                  <Link to="/Sea">Sea Freight</Link>
                </li>
                <li>
                  <Link to="/Air">Air Freight</Link>
                </li>
                <li>
                  <Link to="/Surface">Surface Transportation</Link>
                </li>
                <li>
                  <Link to="/Warehousing">Warehousing & Distribution</Link>
                </li>
                <li>
                  <Link to="/Multimodal">Multimodal Transportation</Link>
                </li>
                <li>
                  <Link to="/Projects">Projects & Break Bulk</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <h3 className>Contact Info</h3>
              <ul className="footer-contact-list">
                <li className>{companyaddress}</li>
                <li className="mt-2">
                 {companynumber}
                </li>
                <li className="mt-2">
                  {companyemail}
                </li>
                <li className="mt-2">
                  {companyurl}
                </li>
              </ul>
            </div>
          </div>

          <div className="below-section text-center pt-4 mt-5">
            <p className="copy-text">
              © 2023 {companyname}. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
