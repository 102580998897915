import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner " style={{ marginTop: "105px" }}>
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-4 pb-sm-4">
            <h4 className="inner-text-title font-weight-bold pt-sm-5 pt-4">
              Air Freight
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Air Freight
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative">
                <h3 className="title-style mb-sm-3 mb-2">
                  <span>Air Freight</span>
                </h3>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="assets/images/Air Freight.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    The partnership between {companyname} and multiple
                    major airlines ensures cost-effective rates, predictable
                    transit times, reserved allocations, and access to carrier
                    capacity along crucial trade routes.
                  </p>

                  <p>
                    We create unparalleled synergies to boost your business's
                    success, offering an advantage in air freight solutions,
                    dedicated account managers, and a 24/7 service guarantee.
                  </p>

                  <p>Here are some key features:</p>
                  <ul>
                    <li>Consolidation Services</li>
                    <li>Cargo pick-up from the consignee's warehouse</li>
                    <li>Expediting / Vendor Management</li>
                    <li>In-house export packing</li>
                    <li>Comprehensive documentation services</li>
                    <li>Safe handling of both general and perishable cargo</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
