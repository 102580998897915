import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner " style={{ marginTop: "105px" }}>
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-4 pb-sm-4">
            <h4 className="inner-text-title font-weight-bold pt-sm-5 pt-4">
              Warehousing & Distribution
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Warehousing & Distribution
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative">
                <h3 className="title-style mb-sm-3 mb-2">
                  <span>Warehousing & Distribution</span>
                </h3>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="assets/images/Warehousing & Distribution.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    We provide top-tier warehouse services to cater to our
                    clients' needs for secure product storage and transit. Our
                    extensive shipping and packing solutions are tailored to the
                    unique requirements of each business. With a vast network of
                    well-equipped warehouses nationwide, we efficiently manage
                    our clients' imported and exported goods.
                  </p>

                  <p>
                    Our robust infrastructure is powered by a scalable supply
                    chain, defined by our transparent and responsive processes
                    and services.
                  </p>

                  <p>
                    Enhance your business strategy with our 3PL (Third-Party
                    Logistics) services, which encompass modern warehousing
                    facilities, skilled labor, and expert supply chain
                    management.
                  </p>

                  <p>Our Contract Logistics offerings include:</p>
                  <ul>
                    <li>End-to-end surveillance</li>
                    <li>
                      Material handling for standard and non-standard cargo, ODC
                      shipments, perishables, and volatile goods
                    </li>
                    <li>Asset-light model</li>
                    <li>Customized storage solutions</li>
                    <li>Flexible space and transportation options</li>
                    <li>
                      MHE (Material Handling Equipment), HPT (Heavy Payload
                      Transporters), Forklifts, Reach Trucks
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
