import { Outlet, Link } from "react-router-dom";
import Getquote from "../pages/Getquote";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = (
  {
    /*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/
  }
) => {
  return (
    <>
      {" "}
      <header id="site-header" className="fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand" to="/">
              <img
                src="assets/images/logo.png"
                alt="logo"
                style={{ height: "90px" }}
              ></img>
            </Link>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa icon-expand fa-bars" />
              <span className="navbar-toggler-icon fa icon-close fa-times" />
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/About">
                    Who We Are
                  </Link>
                </li>
                 <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    What We Do <i className="fas fa-angle-down" />
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-menu-2"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to="/Sea">
                      Sea Freight
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Air">
                      Air Freight
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Surface">
                      Surface Transportation
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Warehousing">
                      Warehousing & Distribution
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Multimodal">
                      Multimodal Transportation
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Projects">
                      Projects & Break Bulk
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Whyus">
                    Why Choose Us
                  </Link>
                </li>
               
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Trading Services <i className="fas fa-angle-down" />
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                   <li>
                      <Link className="dropdown-item" to="/Trading">
                      Aerospace & Aviation
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Automotive
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Beverages
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Government & Defence
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      High Tech
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Industrial
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Contact">
                    Contact
                  </Link>
                </li>
              </ul>
              
            </div>
            {/* toggle switch for light and dark theme */}
            <div className="cont-ser-position">
            <Link to="/Getquote" className="btn btn-style">
             Request For Quote
            </Link>
            </div>
            {/* //toggle switch for light and dark theme */}
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
