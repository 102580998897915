import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner " style={{ marginTop: "105px" }}>
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-4 pb-sm-4">
            <h4 className="inner-text-title font-weight-bold pt-sm-5 pt-4">
            Our Services
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Our Services
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="w3l-servicesblock2 py-5" id="services">
        <div className="container py-lg-5 py-md-4 py-2">
          <h3 className="title-style text-center mb-lg-5 mb-4">
            Services we're <span>offering</span>
          </h3>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Sea" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Sea Freight.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Sea Freight</h4>
                    <p> Our state-of-the-art solutions create a pathway to your
                    sales and procurement markets, ensuring your shipments reach</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Air" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Air Freight.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Air Freight</h4>
                    <p> The partnership between {companyname} and multiple
                    major airlines ensures cost-effective rates, predictable</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Surface" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Surface Transportation.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Surface Transportation</h4>
                    <p>Our land transport services are precisely tailored to meet
                    the specific needs of our clients. These services offer you</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Warehousing" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Warehousing & Distribution.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Warehousing & Distribution</h4>
                    <p>We provide top-tier warehouse services to cater to our
                    clients' needs for secure product storage and transit.</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Multimodal" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Multimodal Transportation.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Multimodal Transportation</h4>
                    <p>When transportation is managed by a single organization,
                    your company can achieve significant time and cost savings.</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Projects" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Projects & Break Bulk.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Projects & Break Bulk</h4>
                    <p> We excel in the management of project cargo, demanding a
                    high degree of skill, care, and precision. Our expertise</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w3l-news py-5" id="homeblog">
        <div className="container py-lg-5 py-md-4 py-2">
          <h3 className="title-style text-center mb-lg-5 mb-4">
            Our <span>Industries</span>
          </h3>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Aerospace & Aviation.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      Aerospace & Aviation
                    </Link>
                  </h4>
                  <p className="mt-3"> {companyname} plays a vital role in strengthening the entire
                  aircraft manufacturing supply chain. We provide unwavering..</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Automotive.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      Automotive
                    </Link>
                  </h4>
                  <p className="mt-3"> Our service excels in Purchase Order (PO) Management. Our
                  in-house developed PO Management System provides real-time..</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Beverages.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      Beverages
                    </Link>
                  </h4>
                  <p className="mt-3"> In the beverage industry, we deliver excellence through
                  specialized logistics and storage solutions that safeguard the..</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Government & Defence.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      Government & Defence
                    </Link>
                  </h4>
                  <p className="mt-3"> With meticulous planning, our professionals ensure the
                  seamless and secure loading and unloading of your cargo at..</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/High Tech.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      High Tech
                    </Link>
                  </h4>
                  <p className="mt-3"> Offering comprehensive supply chain solutions, we specialize
                  in tailored services for the consumer electronics industry...</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Industrial.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      Industrial
                    </Link>
                  </h4>
                  <p className="mt-3">{companyname} offers a comprehensive range of services
                  tailored for the Aerospace and Aviation sectors, providing</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
