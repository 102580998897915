import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner " style={{ marginTop: "105px" }}>
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-4 pb-sm-4">
            <h4 className="inner-text-title font-weight-bold pt-sm-5 pt-4">
              Sea Freight
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Sea Freight
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative">
                <h3 className="title-style mb-sm-3 mb-2">
                  <span>Sea Freight</span>
                </h3>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="assets/images/Sea Freight.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    Our state-of-the-art solutions create a pathway to your
                    sales and procurement markets, ensuring your shipments reach
                    their destinations seamlessly. We serve as a unified
                    logistics hub, streamlining the movement of all your
                    products along the supply chain through our partnerships
                    with numerous carriers.
                  </p>

                  <p>Our comprehensive range of services encompasses:</p>
                  <ul>
                    <li>Purchase order management</li>
                    <li>Shipment tracking</li>
                    <li>Flexible and reliable shipping schedules</li>
                    <li>Capacity preservation during peak demand periods</li>
                  </ul>

                  <p>
                    We offer tailored solutions that enable you to navigate the
                    complexities of shipping successfully.
                  </p>

                  <p>Our personalized services include:</p>
                  <ul>
                    <li>Cargo consolidation</li>
                    <li>
                      FCL (Full Container Load) and LCL (Less than Container
                      Load) consolidation
                    </li>
                    <li>Multimodal transportation via sea, air, and road</li>
                    <li>Customs clearance and brokerage services</li>
                    <li>Cargo insurance coverage</li>
                    <li>Pickup, packing options, delivery, and distribution</li>
                    <li>Comprehensive documentation services</li>
                    <li>
                      Handling sensitive freight, including dry, liquid, bulk,
                      and perishable goods
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
