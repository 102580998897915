import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner " style={{ marginTop: "105px" }}>
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-4 pb-sm-4">
            <h4 className="inner-text-title font-weight-bold pt-sm-5 pt-4">
              Multimodal Transportation
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Multimodal Transportation
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative">
                <h3 className="title-style mb-sm-3 mb-2">
                  <span>Multimodal Transportation</span>
                </h3>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="assets/images/Multimodal Transportation.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    When transportation is managed by a single organization,
                    your company can achieve significant time and cost savings.
                    Leverage our extensive network and expertise to access the
                    ideal blend of multimodal transportation options.
                  </p>

                  <p>
                    Our flexible inland transportation services cater to both
                    FCL (Full Container Load) and LCL (Less than Container Load)
                    shipments, utilizing rail, barge, and road, ensuring that
                    your goods are collected and delivered precisely when and
                    where needed.
                  </p>

                  <p>
                    Our team excels in optimizing routes, managing
                    documentation, coordinating schedules, and adhering to
                    regulatory requirements, thereby creating new avenues for
                    expansion across various industries.
                  </p>

                  <p>
                    For all your multimodal transportation needs, choose{" "}
                    {companyname} Logistics as your integrated solution service
                    provider.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
