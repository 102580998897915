import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/* hero slider Start */}
      <div
        className="banner-wrap w3-banner jarallax"
        style={{ paddingTop: "105px" }}
      >
        <video autoPlay loop muted>
          <source src="assets/images/video.mp4"></source>
        </video>
      </div>
      {/* hero slider end */}
      {/* home services block */}
      <div className="w3l-servicesblock2 py-5" id="services">
        <div className="container py-lg-5 py-md-4 py-2">
          <h3 className="title-style text-center mb-lg-5 mb-4">
            Services we're <span>offering</span>
          </h3>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Sea" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Sea Freight.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Sea Freight</h4>
                    <p> Our state-of-the-art solutions create a pathway to your
                    sales and procurement markets, ensuring your shipments reach</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Air" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Air Freight.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Air Freight</h4>
                    <p> The partnership between {companyname} and multiple
                    major airlines ensures cost-effective rates, predictable</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Surface" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Surface Transportation.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Surface Transportation</h4>
                    <p>Our land transport services are precisely tailored to meet
                    the specific needs of our clients. These services offer you</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Warehousing" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Warehousing & Distribution.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Warehousing & Distribution</h4>
                    <p>We provide top-tier warehouse services to cater to our
                    clients' needs for secure product storage and transit.</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Multimodal" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Multimodal Transportation.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Multimodal Transportation</h4>
                    <p>When transportation is managed by a single organization,
                    your company can achieve significant time and cost savings.</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="grids5-info">
                <Link to="/Projects" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Projects & Break Bulk.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="image-overlay">
                    <h4>Projects & Break Bulk</h4>
                    <p> We excel in the management of project cargo, demanding a
                    high degree of skill, care, and precision. Our expertise</p>
                  </div>
                  <div className="iconic-plus">
                    <i className="fas fa-plus" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //home services block */}
      {/* home page service grids */}
      <section className="home-services py-5" id="services">
        <div className="container py-lg-5 py-md-4 py-2">
          <h3 className="title-style text-center mb-lg-5 mb-4">
            How <span>it </span>Works
          </h3>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="box-wrap" style={{ height: "280px" }}>
                <div className="icon"></div>
                <h4 className="number">01</h4>
                <h4>
                  <Link>Discover Options</Link>
                </h4>
                <p>
                  Browse through our offerings encompassing air, sea, road
                  transportation, warehouse solutions, and digital logistics.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="box-wrap" style={{ height: "280px" }}>
                <div className="icon"></div>
                <h4 className="number">02</h4>
                <h4>
                  <Link>Request a Quotation</Link>
                </h4>
                <p>
                  Engage with our logistics specialists to find a tailored
                  solution that aligns with your company's needs.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="box-wrap" style={{ height: "280px" }}>
                <div className="icon"></div>
                <h4 className="number">03</h4>
                <h4>
                  <Link>Place Your Order</Link>
                </h4>
                <p>
                  Tailor your order to match your specific supply chain
                  requirements
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="box-wrap" style={{ height: "280px" }}>
                <div className="icon"></div>
                <h4 className="number">04</h4>
                <h4>
                  <Link>Take Delivery of Your Shipment</Link>
                </h4>
                <p>
                  Enhance your upcoming shipment by receiving your goods and
                  reviewing your processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //home page service grids */}
      {/* about block */}
      <section className="w3l-about py-5" id="about">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 section-width pe-xl-5">
              <h3 className="title-style">
                ABOUT <span>US</span>
              </h3>
              <p className="mt-4">
                At {companyname}, we specialize in providing comprehensive
                Freight Forwarding and business trade solutions that empower
                your business to extend its presence to every desired corner.
                Leveraging our profound understanding of both internal and
                external customer demands, we strategically approach our
                services, delivering an all-encompassing solution that propels
                your brand forward. Our core philosophy revolves around
                achieving operational excellence, offering viable and
                cost-effective technology-driven solutions, even for the most
                intricate logistics and supply chain management challenges. We
                embody this philosophy through our work, fueled by a
                forward-thinking and progressive mindset.
              </p>
              <Link to="/About" className="btn btn-style mt-4">
                {" "}
                Know more about us
              </Link>
            </div>
            <div className="col-lg-6 history-info mt-lg-0 mt-5 pt-md-4 pt-2">
              <div className="position-relative img-border">
                <img
                  src="assets/images/About 1.jpg"
                  className="img-fluid video-popup-image"
                  alt="video-popup"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //about block */}
      {/* content block */}
      <section className="w3l-content1 py-5">
        <div className="container py-md-5 py-sm-4 py-2">
          <div className="row align-items-center py-4">
            <div className="col-lg-9">
              <div className="bottom-info">
                <h3 className="title-style text-white">
                  Why <span>Choose</span> Us<span></span>
                </h3>
                <p className="mt-4 text-light">
                  At Sunrise Enterprises, our mission is to bring about
                  transformative transportation and logistics solutions that
                  serve as key drivers of success for both businesses and
                  individuals in our care. Our foundation rests upon three core
                  values: an unwavering commitment to our customers, an
                  unrelenting pursuit of excellence, and a continuous drive for
                  innovative thinking. Whether your business is a small startup
                  or a large enterprise, we provide tailor-made leasing
                  solutions that flexibly adapt to your unique and ever-evolving
                  needs.
                </p>
                <p className="mt-4 text-light">
                  As a global leader in logistics, transportation, and
                  distribution services, we address the transportation and
                  logistics challenges faced by individuals, small businesses,
                  and complex multinational corporations alike. Our objective is
                  to nurture efficient supply chains, streamline distribution
                  processes, and facilitate seamless transportation for all our
                  clients. With a steadfast focus on continuous enhancement, we
                  employ state-of-the-art strategies to elevate your logistics
                  operations and contribute to your overall success.
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-lg-end">
              <Link
                to="/Getquote"
                className="btn btn-style mt-lg-0 mt-md-5 mt-4"
              >
                Request For Quote
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* //content block */}

      {/* blog section */}
      <div className="w3l-news py-5" id="homeblog">
        <div className="container py-lg-5 py-md-4 py-2">
          <h3 className="title-style text-center mb-lg-5 mb-4">
            Our <span>Industries</span>
          </h3>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Aerospace & Aviation.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      Aerospace & Aviation
                    </Link>
                  </h4>
                  <p className="mt-3"> {companyname} plays a vital role in strengthening the entire
                  aircraft manufacturing supply chain. We provide unwavering..</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Automotive.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      Automotive
                    </Link>
                  </h4>
                  <p className="mt-3"> Our service excels in Purchase Order (PO) Management. Our
                  in-house developed PO Management System provides real-time..</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Beverages.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      Beverages
                    </Link>
                  </h4>
                  <p className="mt-3"> In the beverage industry, we deliver excellence through
                  specialized logistics and storage solutions that safeguard the..</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Government & Defence.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      Government & Defence
                    </Link>
                  </h4>
                  <p className="mt-3"> With meticulous planning, our professionals ensure the
                  seamless and secure loading and unloading of your cargo at..</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/High Tech.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      High Tech
                    </Link>
                  </h4>
                  <p className="mt-3"> Offering comprehensive supply chain solutions, we specialize
                  in tailored services for the consumer electronics industry...</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="grids5-info">
                <Link to="/Trading" className="blog-image d-block zoom">
                  <img
                    src="assets/images/Industrial.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                  <div className="image-overlay">
                    <span className="fas fa-plus" />
                  </div>
                </Link>
                <div className="blog-info card-body blog-details">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <h4>
                    <Link to="/Trading" className="blog-desc">
                      Industrial
                    </Link>
                  </h4>
                  <p className="mt-3">{companyname} offers a comprehensive range of services
                  tailored for the Aerospace and Aviation sectors, providing</p>
                  <Link to="/Trading" className="read">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //blog section */}
      <Footer />
    </>
  );
}
