import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <section className="inner-banner " style={{ marginTop: "105px" }}>
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-4 pb-sm-4">
            <h4 className="inner-text-title font-weight-bold pt-sm-5 pt-4">
              Our Industries
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Our Industries
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-about py-5" id="about">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 section-width pe-xl-5">
              <h3 className="title-style">Aerospace & Aviation</h3>
              <p className="mt-4">
                <p>
                  {companyname} plays a vital role in strengthening the entire
                  aircraft manufacturing supply chain. We provide unwavering
                  support across various stages, from sourcing and procurement
                  to replenishing critical aerospace parts. Our global network
                  of facilities operates 24/7, ensuring timely product
                  deliveries worldwide.
                </p>
                <p>
                  One of our core competencies lies in supplier management,
                  recognizing the evolving complexity of the aircraft
                  manufacturing supply chain. As the number of vendors continues
                  to rise, effective supplier oversight is more crucial than
                  ever. Our cutting-edge technology equips you with tools to
                  assess supplier performance in alignment with purchase orders,
                  delivery schedules, and production deadlines.
                </p>
              </p>
            </div>
            <div className="col-lg-6 history-info mt-lg-0 mt-5 pt-md-4 pt-2">
              <div className="position-relative img-border">
                <img
                  src="assets/images/Aerospace & Aviation.jpg"
                  className="img-fluid video-popup-image"
                  alt="video-popup"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 history-info mt-lg-0 mt-5 pt-md-4 pt-2">
              <div className="position-relative img-border">
                <img
                  src="assets/images/Automotive.jpg"
                  className="img-fluid video-popup-image"
                  alt="video-popup"
                />
              </div>
            </div>
            <div className="col-lg-6 section-width pe-xl-5">
              <h3 className="title-style text-end">Automotive</h3>
              <p className="mt-4 ps-4">
                <p>
                  Our service excels in Purchase Order (PO) Management. Our
                  in-house developed PO Management System provides real-time
                  insights into the location of your containers, tracking their
                  movement, storage, and arrival. Accessing this information is
                  effortless, ensuring you stay informed whether you're at home
                  or in the office.
                </p>
                <p>
                  We understand that a well-orchestrated supply chain is
                  paramount in the automotive industry, where precision and
                  punctuality are non-negotiable. With {companyname} by your
                  side, your automobile production line will operate seamlessly,
                  supported by streamlined supplier management and a
                  comprehensive PO Management System that keeps you informed and
                  empowered.
                </p>{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 section-width pe-xl-5">
              <h3 className="title-style">Beverages</h3>
              <p className="mt-4">
                <p>
                  In the beverage industry, we deliver excellence through
                  specialized logistics and storage solutions that safeguard the
                  integrity of your drinks. Our reliable multi-modal services
                  are tailored to preserve the quality of every beverage,
                  orchestrating efficient deliveries that exceed expectations.
                </p>

                <p>
                  Whether it's the seamless distribution of beer, wine, or
                  spirits, our expertise in handling customs and excise matters
                  ensures a smooth passage for your products. We understand
                  regulatory intricacies, providing hassle-free solutions to
                  navigate any legalities that may arise. Our facilities, both
                  wet and dry bonded, offer secure storage, further enhancing
                  the assurance of uncompromised quality.
                </p>
              </p>
            </div>
            <div className="col-lg-6 history-info mt-lg-0 mt-5 pt-md-4 pt-2">
              <div className="position-relative img-border">
                <img
                  src="assets/images/Beverages.jpg"
                  className="img-fluid video-popup-image"
                  alt="video-popup"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 history-info mt-lg-0 mt-5 pt-md-4 pt-2">
              <div className="position-relative img-border">
                <img
                  src="assets/images/Government & Defence.jpg"
                  className="img-fluid video-popup-image"
                  alt="video-popup"
                />
              </div>
            </div>
            <div className="col-lg-6 section-width pe-xl-5">
              <h3 className="title-style text-end">Government & Defence</h3>
              <p className="mt-4 ps-4">
                <p>
                  With meticulous planning, our professionals ensure the
                  seamless and secure loading and unloading of your cargo at
                  designated sites. Thorough preparations precede the operation,
                  guaranteeing a smooth and successful process. We recognize the
                  critical importance of this stage, and our expertise in cargo
                  handling shines as we implement necessary protocols for a
                  problem-free operation.
                </p>

                <p>
                  We take charge of obtaining essential clearances for both
                  cargo and personnel movement, minimizing disruptions and
                  streamlining the entire process. Our commitment extends to
                  managing every aspect of the operation, ensuring a cohesive
                  and coordinated effort.
                </p>
              </p>
            </div>
          </div>
        </div>
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 section-width pe-xl-5">
              <h3 className="title-style">High Tech</h3>
              <p className="mt-4">
                <p>
                  Offering comprehensive supply chain solutions, we specialize
                  in tailored services for the consumer electronics industry.
                  Our expertise spans various requirements, allowing us to
                  address your unique logistical needs effectively. Whether you
                  require streamlined inbound air and ocean consolidation
                  solutions, efficient DC bypass programs, seamless Ecommerce
                  fulfillment, or precise retail store distribution,{" "}
                  {companyname} is your dedicated logistics partner.
                </p>
              </p>
            </div>
            <div className="col-lg-6 history-info mt-lg-0 mt-5 pt-md-4 pt-2">
              <div className="position-relative img-border">
                <img
                  src="assets/images/High Tech.jpg"
                  className="img-fluid video-popup-image"
                  alt="video-popup"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 history-info mt-lg-0 mt-5 pt-md-4 pt-2">
              <div className="position-relative img-border">
                <img
                  src="assets/images/Industrial.jpg"
                  className="img-fluid video-popup-image"
                  alt="video-popup"
                />
              </div>
            </div>
            <div className="col-lg-6 section-width pe-xl-5">
              <h3 className="title-style text-end">Industrial</h3>
              <p className="mt-4 ps-4">
                <p>
                  {companyname} offers a comprehensive range of services
                  tailored for the Aerospace and Aviation sectors, providing
                  global support for aircraft manufacturing logistics. Our
                  expertise covers civilian and military aircraft operations,
                  delivering top-tier fulfillment logistics solutions. Whether
                  dealing with large-scale engine components or same-day
                  transportation needs, {companyname} is your dedicated partner.
                </p>

                <p>
                  We excel in vendor management and orchestrating complex supply
                  chains, meeting stringent deadlines, and maintaining seamless
                  global operations. Our services are designed to cater to the
                  unique demands of the aerospace and aviation industry,
                  optimizing manufacturing processes and supply chain
                  strategies.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
